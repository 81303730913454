import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Container,
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem as MenuListItem,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";

registerLocale("tr", tr);
setDefaultLocale("tr");

const BaseURL = "https://tugvasamsun.com.tr";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Mavi renk
    },
    secondary: {
      main: "#dc004e", // Kırmızı renk
    },
  },
});

const AdminPanel = () => {
  const [villas, setVillas] = useState([]);
  const [selectedVilla, setSelectedVilla] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bookedDates, setBookedDates] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchVillas = async () => {
      try {
        setVillas([
          { id: "1", name: "Villa Melisa 1" },
          { id: "2", name: "Villa Melisa 2" },
        ]); // Örnek veriler
      } catch (error) {
        console.error("Villa verileri alınamadı:", error);
      }
    };

    fetchVillas();
  }, []);

  useEffect(() => {
    if (selectedVilla) {
      fetchBookedDates();
    }
  }, [selectedVilla]);

  const fetchBookedDates = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/api/booked-dates?villaId=${selectedVilla}`
      );
      const dates = response.data.map((dateObj) => new Date(dateObj.date));
      setBookedDates(dates);
      console.log("Dolu tarihler alındı:", dates);
    } catch (error) {
      console.error("Dolu tarihler alınırken hata oluştu:", error);
    }
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const handleSubmit = async () => {
    try {
      if (!startDate || !endDate || startDate > endDate) {
        setSnackbarMessage("Geçerli bir tarih aralığı seçin.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
        return;
      }

      const adjustedStartDate = addDays(startDate, 1);
      const adjustedEndDate = addDays(endDate, 1);

      const formattedDates = [];
      let currentDate = new Date(adjustedStartDate);
      while (currentDate <= adjustedEndDate) {
        const formattedDate = new Date(
          Date.UTC(
            currentDate.getUTCFullYear(),
            currentDate.getUTCMonth(),
            currentDate.getUTCDate()
          )
        )
          .toISOString()
          .split("T")[0];
        formattedDates.push(formattedDate);
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      await axios.post(`${BaseURL}/api/booked-dates`, {
        villaId: selectedVilla,
        dates: formattedDates,
      });
      setSnackbarMessage("Tarihler başarıyla eklendi");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Tarih eklenirken bir hata oluştu");
      setSnackbarSeverity("error");
      console.error("Hata detayları:", error);
    }
    setShowSnackbar(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Admin Panel
          </Typography>
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuListItem onClick={handleClose}>Sadık Sarıtaş</MenuListItem>
              <MenuListItem onClick={handleLogout}>Çıkış Yap</MenuListItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Box
            component="img"
            src={`${BaseURL}/assets/logoo.png`}
            alt="Logo"
            maxWidth="300px"
            mb={4}
          />

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Villa Seç</InputLabel>
                <Select
                  label="Villa Seç"
                  value={selectedVilla}
                  onChange={(e) => setSelectedVilla(e.target.value)}
                >
                  {villas.map((villa) => (
                    <MenuItem key={villa.id} value={villa.id}>
                      {villa.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography>Başlangıç Tarihi</Typography>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                      // Eğer bitiş tarihi varsa ve yeni seçilen başlangıç tarihi bitiş tarihinden sonra ise bitiş tarihini güncelle
                      if (endDate && date > endDate) {
                        setEndDate(addDays(date, 1));
                      }
                    }
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date()}
                  excludeDates={bookedDates}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  locale="tr"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography>Bitiş Tarihi</Typography>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                      // Eğer başlangıç tarihi varsa ve yeni seçilen bitiş tarihi başlangıç tarihinden önce ise başlangıç tarihini güncelle
                      if (startDate && date < startDate) {
                        setStartDate(addDays(date, -1));
                      }
                    }
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate ? new Date(startDate) : new Date()}
                  excludeDates={bookedDates}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  locale="tr"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ display: "block", margin: "0 auto" }}
              >
                Tarihleri Ekle
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
        >
          <Alert
            onClose={() => setShowSnackbar(false)}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default AdminPanel;
