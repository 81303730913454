import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";

const BaseURL = "https://tugvasamsun.com.tr";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${BaseURL}/api/auth/login`, {
        email,
        password,
      });

      localStorage.setItem("token", response.data.token);
      setSnackbarMessage("Giriş başarılı, yönlendiriliyorsunuz...");
      setSnackbarSeverity("success");
      setShowSnackbar(true);

      setTimeout(() => {
        navigate("/admin");
      }, 2000);
    } catch (error) {
      setSnackbarMessage("Giriş başarısız, lütfen bilgilerinizi kontrol edin");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Box
          component="img"
          src={`${BaseURL}/assets/logoo.png`}
          alt="Logo"
          maxWidth="300px"
          mb={4}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Şifre"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          style={{ marginTop: 16 }}
        >
          Giriş Yap
        </Button>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
